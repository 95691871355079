<template>
    <div>
        <v-dialog
            persistent
            v-model="generateDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="500px"
            min-width="500px"
            max-width="600px"
        >
            <v-card>
                <v-card-title class="success white--text justify-space-between">
                    <span>{{ $t('message.eutrEudrDocuments.addRemoveDocuments') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-overlay
                        :value="loading.get"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-form lazy-validation ref="dataForm" v-model="validForm">
                        <v-layout row pt-3>
                            <v-flex xs12 pl-5 py-1 class="align-center">
                                <v-data-table
                                    :headers="headers"
                                    :items="Contract__eutr_documentation"
                                    :items-per-page="-1"
                                    class="mt-0 appic-table-light specification-table"
                                    hide-default-footer
                                    calculate-widths
                                    dense
                                >
                                    <template v-slot:item.document.title="{ item }">
                                        <span v-if="item.document.type == 'uploaded'">*{{ item.document.title }}</span>
                                        <span v-else>
                                            <v-text-field
                                                :value="item.document.title"
                                                :rules="[rules.title]"
                                                autocomplete="off"
                                                class="force-text-left"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                required
                                                solo
                                                @change="updateItem( item.index, 'document.title',$event)"
                                            />
                                        </span>
                                    </template>
                                    <template v-slot:item.document.note="{ item }">
                                        <v-select
                                            :items="noteTypes"
                                            :value="item.document.note"
                                            :rules="[rules.note]"
                                            class="text-right custom pa-1 bg-lightgrey"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            required
                                            solo
                                            @change="updateItem( item.index, 'document.note',$event)"
                                        ></v-select>
                                    </template>
                                    <template v-slot:item.document.asset_document_id="{ item }">
                                        <div class="ml-2">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn icon @click="deleteItem(item.index, item.document.asset_document_id, item.document.title)" v-on="on">
                                                        <v-icon color="grey">delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('message.deleteThisItem') }}</span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pl-7">
                    <v-btn color="default" small @click="generateDialog = false" >{{ $t('message.dismiss') }}</v-btn>
                    <v-btn color="default" small @click="addItem" >{{ $t('message.add') }}</v-btn>
                    <v-btn color="default" small @click="resetItems">{{ $t('message.reset') }}</v-btn>
                    <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="save()"
                    >{{ $t('message.save') }}</v-btn>
                    <v-btn
                        :loading="loading.generate"
                        color="info"
                        small
                        @click="generatePdf()"
                        v-if="completeInfo == true"
                    >{{ $t('message.generate') }}</v-btn>
                    <v-spacer></v-spacer>
                    <span>*{{ $t('message.uploadedDocuments') }}</span>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div ref="html2PdfDiv" style="display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;">
            <vue-html2pdf
                :show-layout="false"
                :filename="Contract__title + '_EUTR_Due_Diligence_Assessment'"
                :float-layout="true"
                :enable-download="false"
                :html-to-pdf-options="htmlToPdfOptions"
                :paginate-elements-by-height="1120"
                :manual-pagination="true"
                :pdf-quality="2"
                :preview-modal="false"
                pdf-format="a4"
                pdf-content-width="750px"
                ref="html2Pdf"
                @beforeDownload="beforeDownload($event)"
                v-if="validPdf"
            >
                <section slot="pdf-content">
                    <v-app>
                        <div class="d-flex flex-column fill-height paper-a4">
                            <v-col>
                                <v-row no-gutters class="pt-1">
                                    <v-col class="col-2-5">
                                        <img :src="appLogo" class="mb-0 mr-3" width="120px"/>
                                    </v-col>
                                    <v-col class="col-9-5 d-flex flex-column pt-0">
                                        <div>
                                            <span class="header-title">{{ Office__title  }}</span>
                                            <span class="header-subtitle font-weight-bold" style="vertical-align: top" v-if="Office__companyno != ''">{{ '(' + Office__companyno + ')' }}</span>
                                        </div>
                                        <span class="header-subtitle pt-1 pb-0">{{ Office__address1  }}</span>
                                        <span class="header-subtitle pt-1 pb-0">{{ [Office__address2, Office__postcode, Office__city, Office__state, Office__country].filter(Boolean).join(', ')  }}</span>
                                        <div class="d-flex flex-row">
                                            <span class="header-subtitle pt-1 pb-0" v-if="Office__contracttel != null  && Office__contracttel != ''">{{ $t('message.tel') + ': ' + Office__contracttel  }}</span>
                                            <span :class="'header-subtitle pt-1 pb-0' + (Office__contracttel != null && Office__contracttel != '' ? ' ml-10' : '')" v-if="Contract__office_id == 20">{{ getOfficeTaxId() + ' (Head Office)'}}</span>
                                        </div>
                                        <span class="header-subtitle pt-1 pb-0">{{ $t('message.email') + ': ' + Office__contractemail + ' ' + $t('message.website') + ': ' + Office__website }}</span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="pt-8">
                                    <v-col cols="12" class="font-weight-bold text-center">{{ $t('message.eutrEudrDocuments.selfDeclarationHarvestingShort') }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-8">
                                    <v-col cols="12">{{ $t('message.eutrEudrDocuments.formCertifyCompliance') }}:</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-8">
                                    <v-col cols="4">{{ $t('message.date') }}:</v-col>
                                    <v-col cols="8">{{ declarationDate }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-8">
                                    <v-col cols="4">{{ $t('message.contractPo') }}:</v-col>
                                    <v-col cols="8" class="font-weight-bold">{{ Contract__title }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-10">
                                    <v-col cols="12" class="font-weight-bold">{{ $t('message.eutrEudrDocuments.statementLegalHarvesting') }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-10">
                                    <v-col cols="12"><p>{{ $t('message.eutrEudrDocuments.legallyHarvestedDocumentation') }}</p></v-col>
                                </v-row>
                                <div class="pt-8"></div>
                                <v-row no-gutters v-for="(document, index) in Contract__eutr_documentation" :key="index" class="py-1">
                                    <v-col cols="6">{{ document.document.title }}</v-col>
                                    <v-col cols="6">{{ $t('message.' + document.document.note) }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-10">
                                    <v-col cols="12"><span class="font-weight-bold">{{ Office__title }}</span></v-col>
                                </v-row>
                                <v-row no-gutters class="pt-10">
                                    <v-col cols="12" class="d-flex flex-column">
                                        <div class="signature-container">
                                            <img :src="Document__signature" width="120px"/>
                                            <img :src="'/static/img/appic/stamps/stampappsdnbhd.png'" height="80px"/>
                                        </div>
                                        <div class="signature-underline"></div>
                                        <span>{{ Document__signatory }}</span>
                                        <span>{{ Document__signatory_position }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                    </v-app>
                </section>
            </vue-html2pdf>
        </div>
    </div>
</template>

<script>
import {log} from "Helpers/helpers"
import VueHtml2pdf from "vue-html2pdf";
import AppConfig from "Constants/AppConfig";
import {mapActions, mapGetters} from "vuex";
import {api} from "Api";
import {months} from "@/store/modules/appic/constants";
import S3 from 'aws-s3-pro';

export default {
    name: "SelfDeclarationLegalHarvesting",
    props: ['contractId','dialog'],
    components: {VueHtml2pdf},
    data() {
        return {
            appLogo: AppConfig.appSessionLogo,
            months: months,
            Contract__title: null,
            Contract__office_id: 1,
            Contract__eutr_documentation: [],
            Document__signatory: null,
            Document__signatory_position: null,
            Document__signature: null,
            Office__title: null,
            Office__address1: null,
            Office__address2: null,
            Office__city: null,
            Office__contracttel: null,
            Office__companyno: null,
            Office__country: null,
            Office__currency_id: 1,
            Office__contractemail: null,
            Office__gstno: null,
            Office__postcode: null,
            Office__state: null,
            Office__stamp: null,
            Office__stamp_ws: null,
            Office__taxno_type: null,
            Office__website: null,
            completeInfo: false,
            generate_dialog: false,
            validPdf: false,
            htmlToPdfOptions: {
                image: {
                    type: 'jpeg',
                    quality: 0.95
                },
                html2canvas: {
                    scale: 1.5
                }
            },
            loading: {
                get: false,
                generate: false,
                save: false
            },
            rules: {
                note: v => !!v || this.$t('message.required'),
                title: v => !!v || this.$t('message.required')
            },
            validForm: false
        }
    },
    computed: {
        ...mapGetters('country', {
            allCountries: 'allCountries'
        }),
        ...mapGetters('user', {
            s3Keys: 's3Keys'
        }),
        baseURL(){
            return 'https://apptimber-appic.s3-ap-southeast-1.amazonaws.com'
        },
        config() {
            return {
                bucketName: 'apptimber-appic',
                dirName: 'others',
                region: 'ap-southeast-1',
                accessKeyId: this.s3Keys.id,
                secretAccessKey: this.s3Keys.secret,
                s3Url: this.baseURL
            }
        },
        declarationDate() {
            const date = new Date()
            const month = this.months.find(m => m.Month.id == date.getMonth() + 1)
            return month.Month.text + ' ' + date.getDate().toString() + ', ' + date.getFullYear().toString()
        },
        generateDialog: {
            get() {
                return this.generate_dialog;
            },
            set(value){
                this.generate_dialog = value
                if(value == false) {
                    this.Contract__eutr_documentation = []
                    this.$emit('dialog-closed')
                }
            }
        },
        headers() {
            return [
                {text: this.$t('message.title'), value: 'document.title', class: 'light-green lighten-3 pa-1', sortable: false, searchable: false},
                {text: this.$t('message.note'), value: 'document.note', class: 'light-green lighten-3 pa-1', sortable: false, searchable: false},
                {text: this.$t('message.action'), value: 'document.asset_document_id', class: 'light-green lighten-3 pa-1 width-1-pct', sortable: false, searchable: false}
            ]
        },
        noteTypes() {
            return [
                {value: 'nil', text: this.$t('message.nil')},
                {value: 'attached', text: this.$t('message.attached')},
                {value: 'to_follow_after_shipment', text: this.$t('message.toFollowAfterShipment')}
            ]
        },
        S3Client(){
            return new S3(this.config);
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                this.loading.get = true
                this.loadEutrDocumentById(this.contractId)
                    .then(() => {
                        this.loading.get = false
                    })
                    .catch((error) => {
                        this.loading.get = false
                        this.$toast.error(error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            } else {

            }
            this.generate_dialog = value
        }
    },
    methods: {
        ...mapActions('country', {
            getAllCountries: 'getAllCountries'
        }),
        addItem() {
            const newItem = {
                document: {
                    type: null,
                    asset_document_id: null,
                    note: 'attached',
                    title: ''
                }
            }
            this.Contract__eutr_documentation.push(newItem)
            this.reIndexList()
            this.completeInfo = false
        },
        async beforeDownload({html2pdf, options, pdfContent}) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                let reader = new FileReader()
                let _this = this
                reader.readAsDataURL(pdf.output('blob'));
                reader.onloadend = function () {
                    const fileName = _this.Contract__title.replace(' ','_') + '_Self_Declaration_for_Legal_Harvesting'
                    const uploadObject = new File([pdf.output('blob')], fileName + '.pdf')
                    _this.S3Client
                        .uploadFile(uploadObject, fileName)
                        .then((response) => {
                            if (response.status == 204) {
                                // create asset document in DB
                                let document = {
                                    id: null,
                                    asset: 'shipping',
                                    asset_id: parseInt(_this.contractId),
                                    asset_title: 'Self Declaration for Legal Harvesting',
                                    doctype_id: 414,
                                    location: response.location,
                                    uploaded: Date.now(),
                                    customeraccess: 1,
                                    asset_type: 'pdf'
                                }
                                const promise = new Promise((resolve, reject) => {
                                    api
                                        .post('/documents', {
                                            document: document
                                        })
                                        .then((response) => {
                                            if (response.data.status == 'success') {
                                                resolve(response.data.data)
                                            } else {
                                                reject(response.data.status)
                                            }
                                        })
                                        .catch((err) => {
                                            reject(err)
                                        })
                                })

                                promise
                                    .then((newDocument) => {
                                        document['id'] = newDocument?.id
                                        // document['uploaded'] = newDocument?.uploaded
                                        const fileObject = {
                                            AssetDocument: document
                                        }
                                        _this.$emit('pdf-uploaded', fileObject)
                                        _this.loading.generate = false
                                        _this.generateDialog = false
                                    })
                                    .catch((e) => {
                                        _this.$toast.error(e,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        _this.$emit('pdf-not-uploaded')
                                        _this.loading.generate = false
                                    })
                            }
                        })
                        .catch((e) => {
                            _this.$toast.error(e,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            _this.$emit('pdf-not-uploaded')
                            _this.loading.generate = false
                        })
                }
            })
        },
        async deleteItem(idx, id, title) {
            if(await this.$root.$confirm(this.$t('message.deleteDocument') + ' ' +  title, this.$t('message.confirmations.continueDocumentDelete'), {color: 'orange'})){
                try {
                    this.Contract__eutr_documentation.splice(idx, 1)
                    this.reIndexList()
                    this.completeInfo = false
                } catch(e) {
                    this.$toast.error(e,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            } else {
                return false
            }
        },
        generatePdf() {
            this.validPdf = true
            this.loading.generate = true
            const sleepUntil = async (f, timeoutMs) => {
                return new Promise((resolve, reject) => {
                    const timeWas = new Date();
                    const wait = setInterval(function() {
                        if (f()) {
                            clearInterval(wait);
                            resolve();
                        } else if (new Date() - timeWas > timeoutMs) { // Timeout
                            clearInterval(wait);
                            reject();
                        }
                    }, 20);
                });
            }
            try {
                sleepUntil(() => this.$refs.html2Pdf, 5000)
                    .then(() => {
                        this.$refs.html2Pdf.generatePdf()
                    })
            } catch (e){
                log(e)
            }
        },
        loadEutrDocumentById(contractId) {
            return new Promise((resolve, reject) => {
                api
                    .get("/eutr-eudr/" + contractId + "/sdlh")
                    .then(response => {
                        for(let key in response.data.data[0]){
                            this[key] = response.data.data[0][key]
                        }
                        this.reIndexList()
                        resolve(contractId)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        getOfficeTaxId () {
            let taxId = ''
            if(this.Office__gstno != null && this.Office__taxno_type != null) taxId = this.Office__taxno_type + ': ' + this.Office__gstno
            if(this.Office__gstno != null && this.Office__taxno_type == null) taxId = this.Office__gstno
            return taxId
        },
        reIndexList() {
            this.Contract__eutr_documentation.forEach((doc, idx) => {
                this.Contract__eutr_documentation[idx]['index'] = idx
            })
        },
        async resetItems() {
            if(await this.$root.$confirm(this.$t('message.resetItems'), this.$t('message.confirmations.continueResetItems'), {color: 'orange'})){
                try {
                    const contract = {
                        eutr_documentation: null
                    }
                    api
                        .put('/eutr-eudr/' + this.contractId, {
                            contract: contract
                        })
                        .then((response) => {
                            if(response.data.status == 'success') {
                                this.Contract__eutr_documentation = []
                                this.loadEutrDocumentById(this.contractId)
                                    .then(() => {
                                        this.completeInfo = false
                                    })
                                    .catch((e) => {
                                        this.$toast.error(e,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                            } else {
                                this.$toast.error(response.data.status,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch((e) => {
                            this.$toast.error(e,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                } catch(e) {
                    this.$toast.error(e,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            } else {
                return false
            }
        },
        save() {
            const valid = this.$refs.dataForm.validate()
            if(valid) {
                this.loading.save = true
                const contract = {
                    eutr_documentation: this.Contract__eutr_documentation
                }
                api
                    .put('/eutr-eudr/' + this.contractId,{
                        contract: contract
                    })
                    .then((response)=>{
                        if (response.data.status == 'success') {
                            this.completeInfo = true
                        } else {
                            this.$toast.error(response.data.status,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.save = false
                    })
                    .catch((e)=>{
                        this.$toast.error(e,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            }
        },
        updateItem(index, field, value) {
            const fieldAr = field.split('.')
            // log(this.Contract__eutr_documentation)
            this.Contract__eutr_documentation[index][fieldAr[0]][fieldAr[1]] = value
            this.completeInfo = false
            return true
        }
    }
}
</script>

<style scoped>
@font-face {
    font-family: 'AppicArial';
    src: url('/static/fonts/Arial.woff2') format('woff2'),
    url('/static/fonts/Arial.woff2') format('woff'),
    url('/static/fonts/Arial.woff2') format('truetype');
}
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.col-4-5 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
}
.col-9-5 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
}
.border-top-1 {
    border-top: 1px solid black !important;
}
.border-bottom-1 {
    border-bottom: 1px solid black !important;
}
.border-top-2 {
    border-top: 2px solid black;
}
.border-bottom-2 {
    border-bottom: 2px solid black !important;
}
.document-caption {
    font-size: 16px !important;
    line-height: 18px;
}
.footer-note {
    font-size: 8pt !important;
}
.header-title {
    font-size: 20pt !important;
    line-height: 25px;
    font-weight: bold !important;
}
.header-subtitle {
    font-size: 10.5pt !important;
}
.signature-underline {
    width: 150px;
    height: 1px;
    background-color: black;
    clear: both;
}
.paper-a4 {
    height: 1065px;
    margin-left: 75px;
    margin-top: 35px;
    background-color: #FFFFFF !important;
    color: #000000 !important;
}
.v-application{
    font-family: 'AppicArial' !important;
    line-height:10pt !important;
    font-size:10pt !important;
    padding:2px !important;
    background-color: #FFFFFF !important;
}
body {
    width: 18.5cm !important;
    margin: 0 !important;
}
* {
    text-rendering: geometricprecision !important;
}
.signature-container {
    position: relative;
    width: 100%;
    height: 90px;
}
.signature-container > img {
    position: absolute;
    top:0;
    left:0;
}
.signature-container > img:first-child {
    z-index: 2;
}
.signature-container > img:last-child {
    left: 20px !important;
}
</style>